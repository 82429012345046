import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  Typography,
  Chip,
  CircularProgress,
  Divider,
  Button,
  Drawer,
  Tab,
  Grid,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Menu,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "../../assets/icons/deleteRed.svg";
import Pencil from "../../assets/icons/pencil.svg";
import { MdRemoveRedEye } from "react-icons/md";
import DateRangePicker from "./dateRangePicker";
import "../../styles/reports.scss";
import { setSnackbar } from "../../redux/slices/common.slice";
import {
  getAllTenantReport,
  getAllUsersThunk,
} from "../../redux/slices/tenant.slice";
import { useDispatch } from "react-redux";
import moment from "moment";
import CreateReport from "./createReport";
import DeleteConfigureAction from "./settings/ConfigureAction/DeleteConfigureAction";
import {
  REPORT_OPTION,
  CLASSIFICATION_OPTION,
  INJURY_OPTION,
} from "../../constants/report.constant";
import HistoryIcon from "@mui/icons-material/History";
import { Navigate, useNavigate } from "react-router-dom";
import { RiAddCircleLine, RiCloseLine, RiFilter2Line } from "react-icons/ri";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FilterDrawer from "../layout/filterDrawer";
import { useTranslation } from "react-i18next";

const TableStyle = {
  "& .MuiTable-root": {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          backgroundColor: "#64bcd2",
          color: "#FFFFFF",
          fontSize: "16px",
          "&:last-child": {
            borderBottom: "none",
          },
          "& .MuiTableSortLabel-root": {
            "& svg": {
              fill: "#FFFFFF",
            },
          },
        },
      },
    },

    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          padding: "12px",
          borderColor: "rgba(224, 224, 224, 1)",
          "& .public-status": {
            backgroundColor: "#6EC531",
          },
          "& .private-status": {
            backgroundColor: "#273167",
          },
          "&:focus": {
            outline: "none !important",
          },
        },
        "&:hover": {
          backgroundColor: "rgb(255 115 100 / 5%)",
        },
      },
    },
  },

  "& .MuiTableContainer-root": {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  },
};

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    // padding: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const Reports = () => {
  const [reportTypeFilter, setReportTypeFilter] = useState("all");
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dateRange, setDateRange] = useState(["", ""]); // Initial date range
  const [reportStatus, setReportStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [userData, setUserData] = useState([]);
  const [reportLayout, setReportLayout] = useState(false);
  const [drawerState, setDrawerState] = useState("edit");
  const [selecteduserData, setSelectedUserData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [imageToken, setImageToken] = useState(null);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // Report option
  const ReportOption = REPORT_OPTION;

  // Classfication option
  const ClassficationOption = CLASSIFICATION_OPTION;

  // Injury option
  const InjuryOption = INJURY_OPTION;

  const dispatch = useDispatch();

  const handleFilterDrawer = () => {
    setOpenFilterDrawer(true);
  };

  const fetchAllReport = async () => {
    setIsLoading(true);
    let send_payload = {
      page_number: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      type: reportTypeFilter == "all" ? undefined : reportTypeFilter,
      user_id: userTypeFilter == "all" ? undefined : userTypeFilter,
      status: reportStatus == "All" ? undefined : reportStatus,
    };

    if (dateRange?.length > 0) {
      send_payload["start"] =
        dateRange[0] == ""
          ? undefined
          : moment(dateRange[0]).format("YYYY-MM-DD");
      send_payload["end"] =
        dateRange[1] == ""
          ? undefined
          : moment(dateRange[1]).format("YYYY-MM-DD");
    }
    let response = await dispatch(getAllTenantReport(send_payload));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setTotalRow(payload?.total_object);
      setImageToken(payload?.token);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const handleForm300Button = () => {
    navigate("/reports/select");
  };

  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
        status: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All User", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.first_name} ${element?.last_name}`,
          value: element?.id,
        });
        setUserData(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  useEffect(() => {
    fetchAllReport();
  }, [
    paginationModel,
    reportTypeFilter,
    userTypeFilter,
    dateRange,
    rowsPerPage,
    page,
    reportStatus,
  ]);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [language, setLanguage] = useState("en");

    const changeLanguage = (e, lng) => {
      console.log(lng);
      setLanguage(lng);

      setOpen(true);
    };
    console.log(language);

    const OtherDetails = [
      {
        label: i18n.getFixedT(language)("reportFormLabels.title"),
        value: row?.title,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.description"),
        value: row?.description,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.injuryDescription"),
        value: row?.injury_description,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.classifyOfCase"),
        value: row?.classify_of_case == null ? "-" : row?.classify_of_case,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.illness"),
        value: row?.illness == null ? "-" : row?.illness,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.awayWorkDays"),
        value: row?.number_of_days_injured?.away_work_days || 0,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.jobTransferDays"),
        value: row?.number_of_days_injured?.job_transfer_days || 0,
      },
    ];

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell className="table-report-data-cell">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            className="table-report-data-cell"
          >
            {row?.project?.projectid}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            {row?.user?.user_role != null ? row?.user?.user_role : "-"}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            <Chip label={row?.report_type} color="primary" variant="outlined" />
          </TableCell>

          <TableCell
            align="left"
            className="table-report-data-cell"
          >{`${row?.user?.first_name} ${row?.user?.last_name}`}</TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {moment(row?.report_date).format("DD-MM-YYYY")}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {moment(row?.updated_at).format("DD-MM-YYYY")}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {row?.image_info?.analysis?.where_this_event_occured == null
              ? "-"
              : row?.image_info?.analysis?.where_this_event_occured}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {row?.status == "PENDING" ? (
              <>
                <Chip
                  label={row?.status}
                  style={{ backgroundColor: "#ff4336" }}
                />
              </>
            ) : (
              <>
                <Chip
                  label={row?.status}
                  style={{ backgroundColor: "green" }}
                />
              </>
            )}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="View">
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("view");
                    setSelectedUserData(row);
                  }}
                >
                  <MdRemoveRedEye
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  sx={{ color: "#000000", padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("edit");
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Edit Details"
                    src={Pencil}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  sx={{ color: "#000000", padding: "0" }}
                  onClick={() => {
                    setIsDelete(true);
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Delete Details"
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}
            className="form-other-information-cell"
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box display="flex">
                <Box sx={{ margin: 1 }} className="other-info-div">
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="div"
                      style={{ fontWeight: 700 }}
                    >
                      {i18n.getFixedT(language)("OtherInfo")}
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={language}
                      exclusive
                      onChange={changeLanguage}
                      aria-label={t("languageToggle")}
                      className="toggle-language-btn"
                    >
                      <ToggleButton
                        value="en"
                        className={
                          language === "en" ? "selected" : "nonSelected"
                        }
                      >
                        {i18n.getFixedT(language)("english")}
                      </ToggleButton>
                      <ToggleButton
                        value="hi"
                        className={
                          language === "hi" ? "selected" : "nonSelected"
                        }
                      >
                        {i18n.getFixedT(language)("hindi")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <hr></hr>
                  <ul style={{ width: "700px", paddingLeft: "0px" }}>
                    {OtherDetails.map((details) => (
                      <>
                        <li
                          className="other-info-list"
                          style={{ marginTop: 4 }}
                        >
                          <Typography
                            variant="subtitle1"
                            className="other-info-list-title"
                            width={"100%"}
                            fontWeight={400}
                          >
                            {details.label}
                          </Typography>{" "}
                          <Typography
                            variant="subtitle1"
                            width={"100%"}
                            marginLeft={"5px"}
                            className="other-info-list-description"
                          >
                            {details.value}
                          </Typography>{" "}
                        </li>
                        <hr className="other-info-list-divider"></hr>
                      </>
                    ))}
                  </ul>
                </Box>
                <Box
                  component="img"
                  alt="Descriptive Alt Text" // Replace with your alt text for accessibility
                  src={`${row?.image[0]}?${imageToken}`} // Replace with your image URL
                  sx={{
                    width: 300, // Set the width of the image box
                    height: 300, // Set the height of the image box
                    objectFit: "cover", // This makes the image cover the box without distortion
                    margin: 1,
                    borderRadius: 2,
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  const forms = [
    {
      title: "Form300",
      createPath: "/reports/form300/select",
      historyPath: "/reports/form300",
    },
    {
      title: "Form301",
      createPath: "/reports/form301/select",
      historyPath: "/reports/form301",
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  // const [expanded, setExpanded] = useState("Form 300");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };
  // const handleAccordionChange = (formTitle) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? formTitle : false);
  // };

  return (
    <>
      <Box className="mb-4">
        <Typography variant="h2" component="h2"></Typography>

        <Box variant="div" component="div" style={{ display: "flex" }}>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            <Box sx={{ marginLeft: "15px" }}>
              <Button
                variant="contained"
                className="primary-button add-btn"
                onClick={handleClick}
                endIcon={<ExpandCircleDownIcon />}
              >
                Osha Forms
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    width: "auto",
                    top: "140px !important",
                  },
                }}
                MenuListProps={{
                  sx: {
                    "& .MuiMenuItem-root": {
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "inherit",
                      },
                    },
                  },
                }}
              >
                {forms.map((form, index) => (
                  <React.Fragment key={index}>
                    <MenuItem disabled>
                      <Typography
                        sx={{
                          fontSize: "16px !important",
                          fontWeight: "bold !important",
                          color: "black !important",
                        }}
                      >
                        {form.title}
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <Box className="accordian-btns" sx={{ gap: "20px" }}>
                        <Button
                          variant="contained"
                          className="primary-button"
                          onClick={() => handleMenuItemClick(form.createPath)}
                          endIcon={<RiAddCircleLine />}
                        >
                          Create
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleMenuItemClick(form.historyPath)}
                          endIcon={<HistoryIcon />}
                          sx={{
                            color: "#036185",
                            borderRadius: "7px !important",
                          }}
                        >
                          History
                        </Button>
                      </Box>
                    </MenuItem>
                  </React.Fragment>
                ))}
              </Menu>
            </Box>

            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={handleFilterDrawer}
              style={{ marginLeft: 15, height: "44px" }}
            >
              Filters
            </Button>
          </div>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <TableContainer component={Paper} sx={TableStyle}>
          <Table aria-label="collapsible table" sx={TableStyle}>
            <TableHead>
              <TableRow>
                <TableCell width={20} />
                <TableCell width={150}>Project Id</TableCell>
                <TableCell align="left" width={200}>
                  Job Title
                </TableCell>
                <TableCell align="left" width={200}>
                  Report Type
                </TableCell>
                <TableCell align="left" width={200}>
                  Report By
                </TableCell>
                <TableCell align="left" width={200}>
                  Injury Date
                </TableCell>
                <TableCell align="left" width={200}>
                  Updated At
                </TableCell>
                <TableCell align="left" width={300}>
                  Where Event Occured
                </TableCell>
                <TableCell align="left" width={300}>
                  Report Status
                </TableCell>
                <TableCell align="left" width={300}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow
                  style={{ paddingTop: "12px !important", paddingBottom: 12 }}
                >
                  <TableCell
                    style={{ borderBottom: 0 }}
                    colSpan={10}
                    align="center"
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {reportData?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} style={{ paddingTop: 10 }}>
                        <Typography variant="body2" align="center">
                          {t("noDataAvailable")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    reportData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => <Row key={row.name} row={row} />)
                  )}
                </>
              )}
            </TableBody>
          </Table>
          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRow}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("of")} ${count !== -1 && count}`
              }
            />
          )}
        </TableContainer>
      </Box>

      {reportLayout && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          drawerState={drawerState}
          values={selecteduserData}
          fetchAllReport={fetchAllReport}
          users={userData}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selecteduserData}
          fetchAllKnowledgeBase={fetchAllReport}
          kbmodule={"Report"}
          isLoading={isLoading}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Injury Date
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Report Status
          </Typography>
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select"
            style={{ width: "100%" }}
            onChange={(event) => {
              setReportStatus(event.target.value);
            }}
            value={reportStatus}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            size="small"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="PENDING">Pending</MenuItem>
            <MenuItem value="COMPLETED">Completed</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Report Type
          </Typography>
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select"
            style={{ width: "100%" }}
            onChange={(event) => {
              setReportTypeFilter(event.target.value);
            }}
            value={reportTypeFilter}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            size="small"
          >
            {ReportOption.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Users
          </Typography>
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select"
            style={{ width: "100%" }}
            onChange={(event) => {
              setUserTypeFilter(event.target.value);
            }}
            value={userTypeFilter}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            size="small"
          >
            {userData.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FilterDrawer>
    </>
  );
};

export default Reports;
