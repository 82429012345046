import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  FormControl,
  Divider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";
import { UploadFile } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
const Form301 = ({ mode }) => {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = React.useState({
    title: "abc",
    phone: "7990554541",
    date: "01/02/2003",
    completedBy: "jaydev",
    fullName: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    dob: null,
    dateHired: null,
    gender: "",
    physicianName: "",
    facility: "",
    treatmentStreet: "",
    treatmentCity: "",
    treatmentState: "",
    treatmentZip: "",
    treatedInEmergencyRoom: false,
    hospitalizedOvernight: false,
    caseNumber: "",
    dateOfInjury: null,
    employeeBeganWork: null,
    timeOfEvent: null,
    whatWasEmployeeDoing: "",
    whatHappened: "",
    injuryOrIllness: "",
    objectOrSubstance: "",
    dateOfDeath: null,
  });

  const handleInputChange = (e) => {
    setEmployeeData({
      ...employeeData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (field, date) => {
    setEmployeeData({
      ...employeeData,
      [field]: date,
    });
  };

  useEffect(() => {
    const tempData = {
      title: "abc",
      phone: "7990554541",
      date: "01/02/2003",
      completedBy: "jaydev",
      fullName: "John Doe",
      street: "123 Elm St",
      city: "Springfield",
      state: "IL",
      zip: "62704",
      dob: "1985-04-12",
      dateHired: "2010-06-15",
      gender: "Male",
      physicianName: "Dr. Jane Smith",
      facility: "Springfield Medical Center",
      treatmentStreet: "456 Oak St",
      treatmentCity: "Springfield",
      treatmentState: "IL",
      treatmentZip: "62705",
      treatedInEmergencyRoom: true,
      hospitalizedOvernight: false,
      caseNumber: "A123456789",
      dateOfInjury: "2024-08-22",
      employeeBeganWork: null,
      timeOfEvent: null,
      whatWasEmployeeDoing: "Operating machinery",
      whatHappened: "Machine malfunctioned and caused injury",
      injuryOrIllness: "Fractured arm",
      objectOrSubstance: "Machine part",
      dateOfDeath: null,
    };
    setEmployeeData(tempData);
  }, []);

  const handleSubmit = (key) => {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="action-btns"
        mb={2}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
          className="inner-action-btns"
        >
          {mode !== "view" && (
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              disableRipple
              onClick={() => handleSubmit(false)}
            >
              Save
            </Button>
          )}

          {/* )} */}

          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<UploadFile />}
            disableRipple
            // onClick={() => handleSubmit(true)}
          >
            Export PDF
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          margin: "auto",
          padding: "2rem",
          maxHeight: "560px",
          overflowY: "auto",
        }}
        className="table-container"
      >
        <Grid
          container
          spacing={4}
          className={mode === "view" ? "form-disabled " : ""}
        >
          {/* Information About the Employee */}
          <Grid item xs={12} md={3}>
            <FormLabel>Title</FormLabel>
            <TextField
              fullWidth
              name="title"
              value={employeeData.title}
              onChange={handleInputChange}
              variant="standard"
              sx={{
                marginBottom: "1rem",
                "& .MuiInputBase-root": {
                  height: "40px", // Adjust field height
                  "& input": {
                    padding: "4px 10px", // Adjust padding
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormLabel>Completed By</FormLabel>
            <TextField
              fullWidth
              name="completedBy"
              value={employeeData.completedBy}
              onChange={handleInputChange}
              variant="standard"
              sx={{
                marginBottom: "1rem",
                "& .MuiInputBase-root": {
                  height: "40px", // Adjust field height
                  "& input": {
                    padding: "4px 10px", // Adjust padding
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormLabel>Phone</FormLabel>
            <TextField
              fullWidth
              name="phone"
              value={employeeData.phone}
              onChange={handleInputChange}
              variant="standard"
              sx={{
                marginBottom: "1rem",
                "& .MuiInputBase-root": {
                  height: "40px", // Adjust field height
                  "& input": {
                    padding: "4px 10px", // Adjust padding
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={employeeData.date}
              onChange={(date) => handleDateChange("date", date)}
              customInput={
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="Date"
                  sx={{
                    height: "40px",
                    marginBottom: "1rem",
                    "& input": {
                      padding: "4px 10px", // Adjust padding
                    },
                    "& div": {
                      height: "100%",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
              className="react-datepicker-wrapper"
            />
          </Grid>

          <Grid item xs={12} md={5.7} direction="column">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "1rem",
                    color: "var(--color-theme-secondary)",
                  }}
                >
                  Information about the Employee
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel>Full Name</FormLabel>
                    <TextField
                      fullWidth
                      name="fullName"
                      value={employeeData.fullName}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "1rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Street</FormLabel>
                    <TextField
                      fullWidth
                      name="street"
                      value={employeeData.street}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>City</FormLabel>
                    <TextField
                      fullWidth
                      name="city"
                      value={employeeData.city}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "1rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>State</FormLabel>
                    <TextField
                      fullWidth
                      name="state"
                      value={employeeData.state}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "1rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Zip Code</FormLabel>
                    <TextField
                      fullWidth
                      name="zip"
                      value={employeeData.zip}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "1rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel>Date of Birth</FormLabel>
                    <DatePicker
                      selected={employeeData.dob}
                      onChange={(date) => handleDateChange("dob", date)}
                      customInput={
                        <TextField
                          fullWidth
                          variant="standard"
                          placeholder="Date of Birth"
                          sx={{
                            height: "40px",
                            marginBottom: "1rem",
                            "& input": {
                              padding: "4px 10px", // Adjust padding
                            },
                            "& div": {
                              height: "100%",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                      className="react-datepicker-wrapper"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel>Date Hired</FormLabel>
                    <DatePicker
                      selected={employeeData.dateHired}
                      onChange={(date) => handleDateChange("dateHired", date)}
                      customInput={
                        <TextField
                          fullWidth
                          variant="standard"
                          placeholder="Date Hired"
                          s
                          sx={{
                            height: "40px",
                            marginBottom: "1rem",
                            "& input": {
                              padding: "4px 10px", // Adjust padding
                            },
                            "& div": {
                              height: "100%",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                      className="react-datepicker-wrapper"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Gender</FormLabel>
                    <RadioGroup
                      name="gender"
                      value={employeeData.gender}
                      onChange={handleInputChange}
                      row
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    width: "100% !important",
                    height: "1px",
                    margin: "auto",
                    color: "var(--color-theme-secondary)",
                    borderColor: "var(--color-theme-secondary)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "1rem",
                    color: "var(--color-theme-secondary)",
                  }}
                >
                  Information about the physician or other health care
                  professional
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel>Name of Physician</FormLabel>
                    <TextField
                      fullWidth
                      name="physicianName"
                      value={employeeData.physicianName}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "1rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>
                      If treatment was given away from the worksite, where was
                      it given?
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Facility</FormLabel>
                    <TextField
                      fullWidth
                      name="facility"
                      value={employeeData.facility}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Street</FormLabel>
                    <TextField
                      fullWidth
                      name="treatmentStreet"
                      value={employeeData.treatmentStreet}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>City</FormLabel>
                    <TextField
                      fullWidth
                      name="treatmentCity"
                      value={employeeData.treatmentCity}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "2rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>State</FormLabel>
                    <TextField
                      fullWidth
                      name="treatmentState"
                      value={employeeData.treatmentState}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "2rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Zip Code</FormLabel>
                    <TextField
                      fullWidth
                      name="treatmentZip"
                      value={employeeData.treatmentZip}
                      onChange={handleInputChange}
                      variant="standard"
                      sx={{
                        marginBottom: "2rem",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust field height
                          "& input": {
                            padding: "4px 10px", // Adjust padding
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel>
                        Was employee treated in an emergency room?
                      </FormLabel>
                      <RadioGroup
                        name="treatedInEmergencyRoom"
                        value={
                          employeeData.treatedInEmergencyRoom ? "yes" : "no"
                        }
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              name: "treatedInEmergencyRoom",
                              value: e.target.value === "yes",
                            },
                          })
                        }
                        row
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel>
                        Was employee hospitalized overnight as an in-patient?
                      </FormLabel>
                      <RadioGroup
                        name="hospitalizedOvernight"
                        value={
                          employeeData.hospitalizedOvernight ? "yes" : "no"
                        }
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              name: "hospitalizedOvernight",
                              value: e.target.value === "yes",
                            },
                          })
                        }
                        row
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={0.6}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderRightWidth: 1,
                borderLeftWidth: 1,
                height: "100% !important",
                width: "1px",
                margin: "auto",
                color: "var(--color-theme-secondary)",
                borderColor: "var(--color-theme-secondary)",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.7}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "1rem",
                color: "var(--color-theme-secondary)",
              }}
            >
              Information about the Case
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel>Case Number</FormLabel>
                <TextField
                  fullWidth
                  name="caseNumber"
                  value={employeeData.caseNumber}
                  onChange={handleInputChange}
                  variant="standard"
                  sx={{
                    marginBottom: "1rem",
                    "& .MuiInputBase-root": {
                      height: "40px", // Adjust field height
                      "& input": {
                        padding: "4px 10px", // Adjust padding
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Date of Injury</FormLabel>
                <DatePicker
                  selected={employeeData.dateOfInjury}
                  onChange={(date) => handleDateChange("dateOfInjury", date)}
                  customInput={
                    <TextField
                      fullWidth
                      variant="standard"
                      placeholder="Date of Injury"
                      sx={{
                        height: "40px",
                        marginBottom: "1rem",
                        "& input": {
                          padding: "4px 10px", // Adjust padding
                        },
                        "& div": {
                          height: "100%",
                        },
                      }}
                    />
                  }
                  className="react-datepicker-wrapper"
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Employee Began Work</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className="modal-date-picker time-picker"
                    name="employeBeganWork"
                    value={employeeData.employeeBeganWork}
                    onChange={(e) =>
                      handleDateChange("employeeBeganWork", dayjs(e))
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Time Of Event</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className="modal-date-picker time-picker"
                    value={employeeData.timeOfEvent}
                    onChange={(e) => handleDateChange("timeOfEvent", dayjs(e))}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  What was the employee doing just before the incident occurred?
                </FormLabel>
                <TextField
                  fullWidth
                  name="whatWasEmployeeDoing"
                  value={employeeData.whatWasEmployeeDoing}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  minRows={5}
                  className="form301-textarea"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  What Happened? Tell us how the injury occurred
                </FormLabel>
                <TextField
                  fullWidth
                  name="whatHappened"
                  value={employeeData.whatHappened}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  minRows={5}
                  className="form301-textarea"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>What was the injury or illness?</FormLabel>
                <TextField
                  fullWidth
                  name="injuryOrIllness"
                  value={employeeData.injuryOrIllness}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  minRows={5}
                  className="form301-textarea"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  {" "}
                  What object or substance directly harmed the employee?
                </FormLabel>
                <TextField
                  fullWidth
                  name="objectOrSubstance"
                  value={employeeData.objectOrSubstance}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  minRows={4}
                  sx={{ marginBottom: "1rem" }}
                  className="form301-textarea"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Date of Death (if Employee Died)</FormLabel>
                <DatePicker
                  selected={employeeData.dateOfDeath}
                  onChange={(date) => handleDateChange("dateOfDeath", date)}
                  customInput={
                    <TextField
                      fullWidth
                      variant="standard"
                      placeholder="Date of Death"
                      sx={{
                        height: "40px",
                        marginBottom: "1rem",
                        "& input": {
                          padding: "4px 10px", // Adjust padding
                        },
                        "& div": {
                          height: "100%",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  className="react-datepicker-wrapper"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Form301;
