import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Typography,
  Grid,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { getAllForm300 } from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import FilterDrawer from "../../layout/filterDrawer";
import DateRangePicker from "../dateRangePicker";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { getAllTenantReport } from "../../../redux/slices/tenant.slice";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
export default function Form301Logs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form300HistoryData, setForm300HistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [tokenPDF, setTokenPDF] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]); // Initial date range
  const [searchTerm, setSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState("all");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const fetchAllReport = async () => {
    setIsLoading(true);

    let send_payload = {
      page_number: paginationModel.page + 1,
      page_size: 10000,
      type: JSON.stringify({
        type__in: ["hazard"],
      }),
      user_id: undefined,
      status: undefined,
    };

    let response = await dispatch(getAllTenantReport(send_payload));
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All Report", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.title} `,
          value: element?.id,
        });
        setReports(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllReport();
  }, []);

  const handleDownloadPDF = (pdfLink, fileName) => {
    const link = document.createElement("a");
    link.href = pdfLink + "?" + tokenPDF;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const getAllForms300 = async () => {
      // let response = await dispatch(
      //   getAllForm300({
      //     page_number: paginationModel.page + 1,
      //     page_size: paginationModel.pageSize,
      //   })
      // );
      // const { payload } = response;
      // if (payload?.status) {
      //   setForm300HistoryData(payload?.data);
      //   setTotalRow(payload?.total_object);
      //   setTokenPDF(payload?.token);
      // }
      const form301LogsData = [
        {
          id: 1,
          employee_name: "John Doe",
          report_title: "Employee Injury Report",
          title: "Injury Report",
          created_at: "2024-09-01",
          form_link: "/path/to/pdf1.pdf",
        },
        {
          id: 2,
          employee_name: "Jane Smith",
          report_title: "Annual Safety Audit",
          title: "Safety Audit",
          created_at: "2024-07-15",
          form_link: "/path/to/pdf2.pdf",
        },
        {
          id: 3,
          employee_name: "Robert Brown",
          report_title: "Workplace Accident Analysis",
          title: "Accident Analysis",
          created_at: "2024-06-10",
          form_link: "/path/to/pdf3.pdf",
        },
      ];
      setForm300HistoryData(form301LogsData);
      setTotalRow(form301LogsData?.length);
    };
    getAllForms300();
  }, [paginationModel]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const customStyles = {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "none !important",
      border: "1px solid #0D333F54",
      cursor: "pointer",
      // padding: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  };

  const columns = [
    {
      field: "employee_name",
      headerName: "Employee Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "report_title",
      headerName: "Report Title",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 130,
      renderCell: DateCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const { form_link, id, title } = params.row;
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="View">
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => navigate(`/reports/form301/${id}/view`)}
              >
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => navigate(`/reports/form301/${id}/edit`)}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download PDF">
              <IconButton
                sx={{ color: "#000000", padding: "0" }}
                onClick={() => handleDownloadPDF(form_link, title)}
              >
                <IoDocumentText size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/reports");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate("/reports/form301/select")}
            >
              Form 301
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? form300HistoryData.filter(
                    (item) =>
                      item.employee_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                : form300HistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Report
          </Typography>
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select"
            style={{ width: "100%" }}
            onChange={(event) => {
              setSelectedReport(event.target.value);
            }}
            value={selectedReport}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            size="small"
          >
            {reports?.map((report) => (
              <MenuItem value={report.value}>{report.label}</MenuItem>
            ))}
          </Select>
        </Box>
      </FilterDrawer>
    </>
  );
}
