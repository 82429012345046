import {
  Box,
  Button,
  MenuItem,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Divider,
  Select,
  CircularProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Selectt from "react-select";
import CustomizedDialog from "./Dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import { setSnackbar } from "../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import {
  getAllUsersThunk,
  updateReport,
} from "../../redux/slices/tenant.slice";
import { CleaningServices } from "@mui/icons-material";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const CreateReport = ({
  isLayout,
  setLayout,
  drawerState,
  values,
  fetchAllReport,
}) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    event_occurred: "",
    injury_description: "",
    classify_of_case: "",
    away_work_days: "",
    job_transfer_days: "",
    illness: "injury",
    type: "",
    employees: [],
  });

  const SetReportValues = () => {
    let data = {
      title: values?.title,
      description: values?.description,
      event_occurred: values?.event_occurred || "",
      injury_description: values?.injury_description || "",
      classify_of_case: values?.classify_of_case || "death",
      illness: values?.illness == null ? "injury" : values?.illness,
      away_work_days: values?.number_of_days_injured?.away_work_days,
      job_transfer_days: values?.number_of_days_injured?.job_transfer_days,
      type: values?.type,
      employees: values?.employees?.map((emp) => ({
        value: emp.id,
        label: emp.first_name + " " + emp.last_name,
      })),
    };
    setInitialValues(data);
    formik.setValues(data);
  };

  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
        status: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setUsers(
        payload?.data?.map((element) => ({
          label: `${element?.first_name} ${element?.last_name}`,
          value: element?.id,
        }))
      );
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  const validationSchema = yup.object({
    title: yup.string().required("Please, Enter report title"),
    description: yup.string().required("Please, Enter report description"),
    event_occurred: yup
      .string()
      .required("Please, Event occured place information"),
    injury_description: yup
      .string()
      .required("Please, Enter injury description"),
    classify_of_case: yup
      .string()
      .required("Please, Select classification of case"),
    illness: yup.string().required("Please, Select illness"),
    employees: yup
      .array()
      .min(1, "Please, select at least one employee")
      .required("Please, select at least one employee"),
    away_work_days: yup.number().when(["classify_of_case", "drawerState"], {
      is: (classify_of_case, drawerState) =>
        drawerState !== "view" &&
        classify_of_case !== "death" &&
        classify_of_case !== "job_transfer_or_restriction" &&
        classify_of_case !== "other_recordable_case",
      then: yup
        .number()
        .required("Away work days are required")
        .min(0, "Away work days cannot be negative")
        .integer("Away work days must be an integer"),
      otherwise: yup.number().nullable(),
    }),
    job_transfer_days: yup.number().when(["drawerState", "classify_of_case"], {
      is: (drawerState, classify_of_case) =>
        !(
          drawerState === "view" ||
          classify_of_case === "death" ||
          classify_of_case === "other_recordable_case"
        ),
      then: yup
        .number()
        .typeError("Must be a number")
        .positive("Must be a positive number")
        .integer("Must be an integer"),
      otherwise: yup.number().notRequired(),
    }),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    SetReportValues();
  }, [values]);
  useEffect(() => {
    fetchAllUser();
  }, [values]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (requestValue) => {
      setIsLoading(true);
      try {
        let requestPayload = {
          ...requestValue,
          employees: requestValue?.employees?.map((item) => item.value),
          number_of_days_injured: {
            away_work_days: requestValue?.away_work_days || 0,
            job_transfer_days: requestValue?.job_transfer_days || 0,
          },
          status: "COMPLETED",
        };
        delete requestPayload["away_work_days"];
        delete requestPayload["job_transfer_days"];
        let response = await dispatch(
          updateReport({
            id: values?.id,
            payload: requestPayload,
          })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Report updated successfully",
            })
          );
          setLayout(false);
          fetchAllReport();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        setIsLoading(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
    },
  });

  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={drawerState === "view" ? "View Report" : "Update Report"}
      open={isLayout}
      handleClose={() => {
        setLayout(false);
      }}
    >
      <DialogContent
        style={{
          maxHeight: "75vh",
        }}
      >
        <form>
          <Grid container spacing={4}>
            {/* Report title input */}
            <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Report Title
                </Typography>
                <TextField
                  disabled={drawerState === "view"}
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Report title"
                  size="small"
                  name="title"
                  {...formik.getFieldProps("title")}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className="form-error-message">
                    {formik.errors.title}
                  </div>
                )}
              </Box>
            </Grid>

            {/* Report description  */}
            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Report description
                </Typography>
                <TextField
                  disabled={drawerState === "view"}
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Report description"
                  size="small"
                  name="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="form-error-message">
                    {formik.errors.description}
                  </div>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Report type
                </Typography>
                <Select
                  disabled={drawerState === "view"}
                  fullWidth
                  labelId=""
                  placeholder="Select case"
                  variant="outlined"
                  id="demo-simple-select-required"
                  value={formik?.values?.type}
                  size="small"
                  label=""
                  onChange={(e) => {
                    formik.setFieldValue("type", e.target.value);
                  }}
                >
                  <MenuItem value={"hazard"}>Hazard</MenuItem>
                  <MenuItem value={"incident"}>Incident</MenuItem>
                  <MenuItem value={"maintenance"}>Maintenance</MenuItem>
                  <MenuItem value={"general_observation"}>
                    General observation
                  </MenuItem>
                  <MenuItem value={"near_miss"}>Near Miss</MenuItem>
                </Select>
                {formik.touched.type && formik.errors.type && (
                  <div className="form-error-message">{formik.errors.type}</div>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Employees
                </Typography>
                <Selectt
                  isMulti // Enables multiple selection
                  name="employees"
                  options={users}
                  value={formik.values.employees} // Value should be an array of selected options
                  onChange={(selectedOptions) => {
                    console.log(selectedOptions);
                    formik.setFieldValue("employees", selectedOptions); // Update formik's state
                  }}
                  placeholder="Select case"
                  isDisabled={drawerState === "view"} // Disable select if needed
                />
                {formik.touched.employees && formik.errors.employees && (
                  <div className="form-error-message">
                    {formik.errors.employees}
                  </div>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Where the event occurred
                </Typography>
                <TextField
                  disabled={drawerState === "view"}
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Where event occurred"
                  size="small"
                  name="event_occurred"
                  {...formik.getFieldProps("event_occurred")}
                />
                {formik.touched.event_occurred &&
                  formik.errors.event_occurred && (
                    <div className="form-error-message">
                      {formik.errors.event_occurred}
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Description about injury
                </Typography>
                <TextField
                  disabled={drawerState === "view"}
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter injury description"
                  size="small"
                  name="injury_description"
                  multiline={true} // This makes it a textarea
                  rows={4} // Number of rows to display for the textarea
                  {...formik.getFieldProps("injury_description")}
                />
                {formik.touched.injury_description &&
                  formik.errors.injury_description && (
                    <div className="form-error-message">
                      {formik.errors.injury_description}
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider style={{ marginBottom: 10 }} />
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography
                  variant="body1"
                  component="label"
                  style={{
                    fontSize: 18,
                    marginTop: 10,
                    marginBottom: 10,
                    fontWeight: 500,
                  }}
                >
                  Classify case
                </Typography>

                <Select
                  defaultValue="death"
                  disabled={drawerState === "view"}
                  fullWidth
                  labelId=""
                  placeholder="Select case"
                  variant="outlined"
                  id="demo-simple-select-required"
                  value={formik?.values?.classify_of_case}
                  size="small"
                  label=""
                  onChange={(e) => {
                    formik.setFieldValue("classify_of_case", e.target.value);
                  }}
                >
                  <MenuItem value={"death"}>Death</MenuItem>
                  <MenuItem value={"days_away_from_work"}>
                    Days away from work
                  </MenuItem>
                  <MenuItem value={"job_transfer_or_restriction"}>
                    Job transfer or restriction
                  </MenuItem>
                  <MenuItem value={"other_recordable_case"}>
                    Other recordable case
                  </MenuItem>
                </Select>
                {formik.touched.classify_of_case &&
                  formik.errors.classify_of_case && (
                    <div className="form-error-message">
                      {formik.errors.classify_of_case}
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider style={{ marginBottom: 10 }} />
              <Typography
                variant="body1"
                component="label"
                style={{
                  fontSize: 18,
                  marginTop: 10,
                  marginBottom: 10,
                  fontWeight: 500,
                }}
              >
                Numered of days the injured or ill worker was
              </Typography>

              {formik?.values?.classify_case == "" && <></>}

              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  Away from work days
                </Typography>

                <TextField
                  disabled={
                    drawerState === "view" ||
                    formik?.values?.classify_case == "death" ||
                    formik?.values?.classify_case ==
                      "job_transfer_or_restriction" ||
                    formik?.values?.classify_case == "other_recordable_case"
                  }
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter days"
                  size="small"
                  name="away_work_days"
                  {...formik.getFieldProps("away_work_days")}
                />
                {formik.touched.away_work_days &&
                  formik.errors.away_work_days && (
                    <div className="form-error-message">
                      {formik.errors.away_work_days}
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid
              item
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: -15 }}
            >
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography variant="body1" component="label">
                  On job transfer or restriction days
                </Typography>
                <TextField
                  disabled={
                    drawerState === "view" ||
                    formik?.values?.classify_case == "death" ||
                    formik?.values?.classify_case == "other_recordable_case"
                  }
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter days"
                  size="small"
                  name="job_transfer_days"
                  {...formik.getFieldProps("job_transfer_days")}
                />
                {formik.touched.job_transfer_days &&
                  formik.errors.job_transfer_days && (
                    <div className="form-error-message">
                      {formik.errors.job_transfer_days}
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid item xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider style={{ marginBottom: 10 }} />
              <Box variant="div" component="div" sx={BootstrapInput}>
                <Typography
                  variant="body1"
                  component="label"
                  style={{
                    fontSize: 18,
                    marginTop: 10,
                    marginBottom: 10,
                    fontWeight: 500,
                  }}
                >
                  Illness
                </Typography>
                <Select
                  disabled={drawerState === "view"}
                  fullWidth
                  labelId=""
                  placeholder="Select illness"
                  variant="outlined"
                  id="demo-simple-select-required"
                  value={formik?.values?.illness}
                  size="small"
                  label=""
                  onChange={(e) => {
                    formik.setFieldValue("illness", e.target.value);
                  }}
                >
                  <MenuItem value={"injury"}>Injury</MenuItem>
                  <MenuItem value={"skin_disorder"}>
                    Skin disorder Skin disorder
                  </MenuItem>
                  <MenuItem value={"respiratory_condition"}>
                    Respiratory condition
                  </MenuItem>
                  <MenuItem value={"poisoning"}>Poisoning</MenuItem>
                  <MenuItem value={"hearing_loss"}>
                    Hearing loss Hearing loss
                  </MenuItem>
                  <MenuItem value={"all_other_illness"}>
                    All other illness
                  </MenuItem>
                </Select>
                {formik.touched.illness && formik.errors.illness && (
                  <div className="form-error-message">
                    {formik.errors.illness}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box className="modal-footers">
          <Stack spacing={2} direction="row">
            {drawerState !== "view" && (
              <Button
                variant="contained"
                className="primary-button"
                disabled={isLoading}
                onClick={formik.handleSubmit}
              >
                {isLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Save
              </Button>
            )}
          </Stack>
        </Box>
      </DialogActions>
    </CustomizedDialog>
  );
};

export default CreateReport;
