import { Box, Drawer, Stack, Tab } from "@mui/material";
import { t } from "i18next";
import { RiCloseLine } from "react-icons/ri";

export default function FilterDrawer({ open, setOpenFilterDrawer, children }) {
  return (
    <Drawer
      className="side-drawer-2 report-side-drawer"
      anchor="right"
      open={open}
      onClose={() => setOpenFilterDrawer(false)}
    >
      <Box className="sidebar-tab-wrapper-2">
        <Box className="close-btn">
          <RiCloseLine onClick={() => setOpenFilterDrawer(false)} />
        </Box>
        <Box className="sidebar-tab">
          <Tab
            className="tab-label-btn contact-label"
            label={t("filters")}
            disabled
          ></Tab>
        </Box>
      </Box>

      <Stack spacing={2} sx={{ padding: "24px !important" }} className="x">
        {children}
      </Stack>
    </Drawer>
  );
}
