import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  Select,
  InputBase,
  Paper,
  Stack,
} from "@mui/material";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded"; // Filled Icon
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { getAllForm300 } from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import DateRangePicker from "../dateRangePicker";
import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
import { FaGlasses } from "react-icons/fa";

// Dummy data for logs with added dates
const form300LogsData = [
  {
    id: 1,
    title: "Employee Injury Report",
    description:
      "A detailed report on the injury sustained by the employee on site.",
    date: "2024-09-01", // Added date
    pdfLink: "/path/to/pdf1.pdf",
  },
  {
    id: 2,
    title: "Annual Safety Audit",
    description:
      "A comprehensive audit of safety protocols followed throughout the year.",
    date: "2024-07-15", // Added date
    pdfLink: "/path/to/pdf2.pdf",
  },
  {
    id: 3,
    title: "Workplace Accident Analysis",
    description:
      "A summary of accidents that occurred in the workplace with preventive measures.",
    date: "2024-06-10", // Added date
    pdfLink: "/path/to/pdf3.pdf",
  },
];

export default function Form300Logs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form300HistoryData, setForm300HistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [tokenPDF, setTokenPDF] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [dateRange, setDateRange] = useState(["", ""]); // Initial date range
  const [searchTerm, setSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const handleDownloadPDF = (pdfLink, fileName) => {
    // Create an anchor element and simulate a click to download the PDF
    const link = document.createElement("a");
    link.href = pdfLink + "?" + tokenPDF;
    link.target = "_blank"; // Opens the link in a new tab
    link.download = fileName; // Set a default file name, you might want to customize it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    const getAllForms300 = async () => {
      setIsLoading(true);
      let response = await dispatch(
        getAllForm300({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setForm300HistoryData(payload?.data);

        setTotalRow(payload?.total_object);
        setTokenPDF(payload?.token);
      }
      setIsLoading(false);
    };
    getAllForms300();
  }, [paginationModel]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const columns = [
    {
      field: "form_name",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 130,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4, // Flex value of 4 (40% of the space if the total flex is 10)
      minWidth: 300, // Adjust minWidth as needed
    },
    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130, // Adjust minWidth as needed
      renderCell: DateCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1, // Remaining space

      renderCell: (params) => {
        const { form_link, id, form_name } = params.row;
        return (
          <>
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="View">
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => navigate(`/reports/form300/${id}/view`)}
                >
                  <MdRemoveRedEye
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  sx={{ color: "#000000", padding: "0px" }}
                  onClick={() => navigate(`/reports/form300/${id}/edit`)}
                >
                  <img
                    alt="Edit Details"
                    src={Pencil}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download PDF">
                <IconButton
                  sx={{ color: "#000000", padding: "0" }}
                  onClick={() => handleDownloadPDF(form_link, form_name)}
                >
                  <IoDocumentText
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/reports");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate("/reports/form300/select")}
            >
              Form 300
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? form300HistoryData.filter((item) =>
                    item.form_name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                : form300HistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={(e) => setDateRange(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>
    </>
  );
}
