import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  FormControl,
  Divider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";
import { UploadFile } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { RiDeleteBinLine } from "react-icons/ri";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
const Form301 = () => {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = React.useState([]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setEmployeeData((prevState) => {
      const updatedEmployees = [...prevState];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [name]: value,
      };
      return updatedEmployees;
    });
  };

  const handleDateChange = (field, date, index) => {
    console.log(field, date);
    setEmployeeData((prevState) => {
      const updatedEmployees = [...prevState];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [field]: date,
      };
      return updatedEmployees;
    });
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const employees = [
      {
        id: 1,
        title: "abc",
        phone: "7990554541",
        date: "01/02/2003",
        completedBy: "jaydev",
        fullName: "John Doe",
        street: "123 Elm St",
        city: "Springfield",
        state: "IL",
        zip: "62704",
        dob: "1985-04-12",
        dateHired: "2010-06-15",
        gender: "Male",
        physicianName: "",
        facility: "",
        treatmentStreet: "",
        treatmentCity: "",
        treatmentState: "",
        treatmentZip: "",
        treatedInEmergencyRoom: false,
        hospitalizedOvernight: false,
        caseNumber: "",
        dateOfInjury: null,
        employeeBeganWork: null,
        timeOfEvent: null,
        whatWasEmployeeDoing: "",
        whatHappened: "",
        injuryOrIllness: "",
        objectOrSubstance: "",
        dateOfDeath: null,
      },
      {
        id: 2,
        title: "abc",
        phone: "7990554541",
        date: "01/02/2003",
        completedBy: "jaydev",
        fullName: "Alice Johnson",
        street: "789 Pine St",
        city: "Shelbyville",
        state: "IL",
        zip: "62565",
        dob: "1990-07-20",
        dateHired: "2015-09-25",
        gender: "Female",
        physicianName: "",
        facility: "",
        treatmentStreet: "",
        treatmentCity: "",
        treatmentState: "",
        treatmentZip: "",
        treatedInEmergencyRoom: false,
        hospitalizedOvernight: false,
        caseNumber: "",
        dateOfInjury: null,
        employeeBeganWork: null,
        timeOfEvent: null,
        whatWasEmployeeDoing: "",
        whatHappened: "",
        injuryOrIllness: "",
        objectOrSubstance: "",
        dateOfDeath: null,
      },
      {
        id: 3,
        title: "abc",
        phone: "7990554541",
        date: "01/02/2003",
        completedBy: "jaydev",
        fullName: "Michael Smith",
        street: "321 Birch Rd",
        city: "Capital City",
        state: "IL",
        zip: "62701",
        dob: "1982-12-05",
        dateHired: "2008-03-10",
        gender: "Male",
        physicianName: "",
        facility: "",
        treatmentStreet: "",
        treatmentCity: "",
        treatmentState: "",
        treatmentZip: "",
        treatedInEmergencyRoom: false,
        hospitalizedOvernight: false,
        caseNumber: "",
        dateOfInjury: null,
        employeeBeganWork: null,
        timeOfEvent: null,
        whatWasEmployeeDoing: "",
        whatHappened: "",
        injuryOrIllness: "",
        objectOrSubstance: "",
        dateOfDeath: null,
      },
    ];
    setEmployeeData(employees);
  }, []);

  console.log(employeeData);

  // const addForm301 = () => {
  //   let data = {
  //     title: "",
  //     phone: "",
  //     date: "",
  //     completedBy: "",
  //     fullName: "",
  //     street: "",
  //     city: "",
  //     state: "",
  //     zip: "",
  //     dob: null,
  //     dateHired: null,
  //     gender: "",
  //     physicianName: "",
  //     facility: "",
  //     treatmentStreet: "",
  //     treatmentCity: "",
  //     treatmentState: "",
  //     treatmentZip: "",
  //     treatedInEmergencyRoom: false,
  //     hospitalizedOvernight: false,
  //     caseNumber: "",
  //     dateOfInjury: null,
  //     employeeBeganWork: null,
  //     timeOfEvent: null,
  //     whatWasEmployeeDoing: "",
  //     whatHappened: "",
  //     injuryOrIllness: "",
  //     objectOrSubstance: "",
  //     dateOfDeath: null,
  //   };
  //   setEmployeeData((current) => [...current, data]);
  // };
  const deleteForm301 = (i) => {
    let tempData = [...employeeData];
    tempData.splice(i, 1);
    setEmployeeData(tempData);
  };
  console.log(employeeData);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="action-btns"
        mb={2}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
          className="inner-action-btns"
        >
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<SaveIcon />}
            disableRipple
            // onClick={() => handleSubmit(false)}
          >
            Save
          </Button>
          {/* )} */}

          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<UploadFile />}
            disableRipple
            // onClick={() => handleSubmit(true)}
          >
            Export PDF
          </Button>
        </Box>
      </Box>
      <Box className="form301-container">
        {employeeData.map((employee, index) => (
          <Box
            sx={{
              display: "flex",

              gap: "20px",
              marginBottom: "16px", // Add space between accordions
            }}
          >
            <Accordion
              key={employee.id}
              expanded={expanded === employee.id}
              onChange={handleChange(employee.id)}
              sx={{
                "& .MuiAccordionSummary-root": {
                  backgroundColor:
                    expanded === employee.id ? "#036185" : "white", // Apply background color when expanded
                  color:
                    expanded === employee.id
                      ? "white !important"
                      : "#036185 !important", // Text color when expanded, header color when not expanded
                  "& .MuiTypography-root": {
                    fontSize: "18px",
                  },
                  border: "1px solid #036185", // Border to make header stand out
                  height: "56px !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: expanded === employee.id ? "white" : "#036185",
                    }}
                  />
                }
                aria-controls={`${employee.id}-content`}
                id={`${employee.id}-header`}
              >
                <Typography variant="h6">{employee.fullName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    margin: "auto",
                    padding: "2rem",
                    maxHeight: "560px",
                    overflowY: "auto",
                  }}
                  className="table-container"
                >
                  <Grid container spacing={4}>
                    {/* Information About the Employee */}
                    <Grid item xs={12} md={3}>
                      <FormLabel>Title</FormLabel>
                      <TextField
                        fullWidth
                        name="title"
                        value={employee.title}
                        onChange={(e) => handleInputChange(e, index)}
                        variant="standard"
                        sx={{
                          marginBottom: "1rem",
                          "& .MuiInputBase-root": {
                            height: "40px", // Adjust field height
                            "& input": {
                              padding: "4px 10px", // Adjust padding
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormLabel>Completed By</FormLabel>
                      <TextField
                        fullWidth
                        name="completedBy"
                        value={employee.completedBy}
                        onChange={(e) => handleInputChange(e, index)}
                        variant="standard"
                        sx={{
                          marginBottom: "1rem",
                          "& .MuiInputBase-root": {
                            height: "40px", // Adjust field height
                            "& input": {
                              padding: "4px 10px", // Adjust padding
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormLabel>Phone</FormLabel>
                      <TextField
                        fullWidth
                        name="phone"
                        value={employee.phone}
                        onChange={(e) => handleInputChange(e, index)}
                        variant="standard"
                        sx={{
                          marginBottom: "1rem",
                          "& .MuiInputBase-root": {
                            height: "40px", // Adjust field height
                            "& input": {
                              padding: "4px 10px", // Adjust padding
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormLabel>Date</FormLabel>
                      <DatePicker
                        selected={employee.date}
                        onChange={(date) =>
                          handleDateChange("date", date, index)
                        }
                        customInput={
                          <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Date"
                            sx={{
                              height: "40px",
                              marginBottom: "1rem",
                              "& input": {
                                padding: "4px 10px", // Adjust padding
                              },
                              "& div": {
                                height: "100%",
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                        className="react-datepicker-wrapper"
                      />
                    </Grid>

                    <Grid item xs={12} md={5.7} direction="column">
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              marginBottom: "1rem",
                              color: "var(--color-theme-secondary)",
                            }}
                          >
                            Information about the Employee
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormLabel>Full Name</FormLabel>
                              <TextField
                                fullWidth
                                name="fullName"
                                value={employee.fullName}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>Street</FormLabel>
                              <TextField
                                fullWidth
                                name="street"
                                value={employee.street}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>City</FormLabel>
                              <TextField
                                fullWidth
                                name="city"
                                value={employee.city}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>State</FormLabel>
                              <TextField
                                fullWidth
                                name="state"
                                value={employee.state}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>Zip Code</FormLabel>
                              <TextField
                                fullWidth
                                name="zip"
                                value={employee.zip}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormLabel>Date of Birth</FormLabel>
                              <DatePicker
                                selected={employee.dob}
                                onChange={(date) =>
                                  handleDateChange("dob", date, index)
                                }
                                customInput={
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    placeholder="Date of Birth"
                                    sx={{
                                      height: "40px",
                                      marginBottom: "1rem",
                                      "& input": {
                                        padding: "4px 10px", // Adjust padding
                                      },
                                      "& div": {
                                        height: "100%",
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CalendarTodayIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                }
                                className="react-datepicker-wrapper"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormLabel>Date Hired</FormLabel>
                              <DatePicker
                                selected={employee.dateHired}
                                onChange={(date) =>
                                  handleDateChange("dateHired", date, index)
                                }
                                customInput={
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    placeholder="Date Hired"
                                    s
                                    sx={{
                                      height: "40px",
                                      marginBottom: "1rem",
                                      "& input": {
                                        padding: "4px 10px", // Adjust padding
                                      },
                                      "& div": {
                                        height: "100%",
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CalendarTodayIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                }
                                className="react-datepicker-wrapper"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>Gender</FormLabel>
                              <RadioGroup
                                name="gender"
                                value={employee.gender}
                                onChange={(e) => handleInputChange(e, index)}
                                row
                              >
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider
                            sx={{
                              borderTopWidth: 1,
                              borderBottomWidth: 1,
                              width: "100% !important",
                              height: "1px",
                              margin: "auto",
                              color: "var(--color-theme-secondary)",
                              borderColor: "var(--color-theme-secondary)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              marginBottom: "1rem",
                              color: "var(--color-theme-secondary)",
                            }}
                          >
                            Information about the physician or other health care
                            professional
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormLabel>Name of Physician</FormLabel>
                              <TextField
                                fullWidth
                                name="physicianName"
                                value={employee.physicianName}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                If treatment was given away from the worksite,
                                where was it given?
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>Facility</FormLabel>
                              <TextField
                                fullWidth
                                name="facility"
                                value={employee.facility}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>Street</FormLabel>
                              <TextField
                                fullWidth
                                name="treatmentStreet"
                                value={employee.treatmentStreet}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>City</FormLabel>
                              <TextField
                                fullWidth
                                name="treatmentCity"
                                value={employee.treatmentCity}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "2rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>State</FormLabel>
                              <TextField
                                fullWidth
                                name="treatmentState"
                                value={employee.treatmentState}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "2rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormLabel>Zip Code</FormLabel>
                              <TextField
                                fullWidth
                                name="treatmentZip"
                                value={employee.treatmentZip}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "2rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel>
                                  Was employee treated in an emergency room?
                                </FormLabel>
                                <RadioGroup
                                  name="treatedInEmergencyRoom"
                                  value={
                                    employee.treatedInEmergencyRoom
                                      ? "yes"
                                      : "no"
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      {
                                        target: {
                                          name: "treatedInEmergencyRoom",
                                          value: e.target.value === "yes",
                                        },
                                      },
                                      index
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel>
                                  Was employee hospitalized overnight as an
                                  in-patient?
                                </FormLabel>
                                <RadioGroup
                                  name="hospitalizedOvernight"
                                  value={
                                    employee.hospitalizedOvernight
                                      ? "yes"
                                      : "no"
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      {
                                        target: {
                                          name: "hospitalizedOvernight",
                                          value: e.target.value === "yes",
                                        },
                                      },
                                      index
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={0.6}>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          borderRightWidth: 1,
                          borderLeftWidth: 1,
                          height: "100% !important",
                          width: "1px",
                          margin: "auto",
                          color: "var(--color-theme-secondary)",
                          borderColor: "var(--color-theme-secondary)",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.7}>
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: "1rem",
                          color: "var(--color-theme-secondary)",
                        }}
                      >
                        Information about the Case
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormLabel>Case Number</FormLabel>
                          <TextField
                            fullWidth
                            name="caseNumber"
                            value={employee.caseNumber}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="standard"
                            sx={{
                              marginBottom: "1rem",
                              "& .MuiInputBase-root": {
                                height: "40px", // Adjust field height
                                "& input": {
                                  padding: "4px 10px", // Adjust padding
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Date of Injury</FormLabel>
                          <DatePicker
                            selected={employee.dateOfInjury}
                            onChange={(date) =>
                              handleDateChange("dateOfInjury", date, index)
                            }
                            customInput={
                              <TextField
                                fullWidth
                                variant="standard"
                                placeholder="Date of Injury"
                                sx={{
                                  height: "40px",
                                  marginBottom: "1rem",
                                  "& input": {
                                    padding: "4px 10px", // Adjust padding
                                  },
                                  "& div": {
                                    height: "100%",
                                  },
                                }}
                              />
                            }
                            className="react-datepicker-wrapper"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel>Employee Began Work</FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="modal-date-picker time-picker"
                              name="employeBeganWork"
                              value={employee.employeeBeganWork}
                              onChange={(time) =>
                                handleDateChange(
                                  "employeeBeganWork",
                                  dayjs(time),
                                  index
                                )
                              }
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel>Time Of Event</FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="modal-date-picker time-picker"
                              value={employee.timeOfEvent}
                              onChange={(time) =>
                                handleDateChange(
                                  "timeOfEvent",
                                  dayjs(time),
                                  index
                                )
                              }
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>
                            What was the employee doing just before the incident
                            occurred?
                          </FormLabel>
                          <TextField
                            fullWidth
                            name="whatWasEmployeeDoing"
                            value={employee.whatWasEmployeeDoing}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="outlined"
                            multiline
                            minRows={5}
                            className="form301-textarea"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>
                            What Happened? Tell us how the injury occurred
                          </FormLabel>
                          <TextField
                            fullWidth
                            name="whatHappened"
                            value={employee.whatHappened}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="outlined"
                            multiline
                            minRows={5}
                            className="form301-textarea"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>What was the injury or illness?</FormLabel>
                          <TextField
                            fullWidth
                            name="injuryOrIllness"
                            value={employee.injuryOrIllness}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="outlined"
                            multiline
                            minRows={5}
                            className="form301-textarea"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>
                            {" "}
                            What object or substance directly harmed the
                            employee?
                          </FormLabel>
                          <TextField
                            fullWidth
                            name="objectOrSubstance"
                            value={employee.objectOrSubstance}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="outlined"
                            multiline
                            minRows={4}
                            sx={{ marginBottom: "1rem" }}
                            className="form301-textarea"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>
                            Date of Death (if Employee Died)
                          </FormLabel>
                          <DatePicker
                            selected={employee.dateOfDeath}
                            onChange={(date) =>
                              handleDateChange("dateOfDeath", date, index)
                            }
                            customInput={
                              <TextField
                                fullWidth
                                variant="standard"
                                placeholder="Date of Death"
                                sx={{
                                  height: "40px",
                                  marginBottom: "1rem",
                                  "& input": {
                                    padding: "4px 10px", // Adjust padding
                                  },
                                  "& div": {
                                    height: "100%",
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarTodayIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            }
                            className="react-datepicker-wrapper"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <IconButton
              onClick={() => deleteForm301(index)}
              sx={{
                backgroundColor: "#036185",
                color: "#fff",
                padding: "10px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#4a9bb3",
                  color: "#fff",
                },
                width: "46px !important",
                height: "46px !important",
                position: "relative",
                top: "6px",
              }}
            >
              <RiDeleteBinLine />
            </IconButton>
          </Box>
        ))}
        {/* <IconButton
          onClick={addForm301}
          sx={{
            backgroundColor: "#036185",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#4a9bb3",
              color: "#fff",
            },
            width: "46px !important",
            height: "46px !important",
            position: "relative",
            top: 0,
          }}
        >
          <AddIcon />
        </IconButton> */}
      </Box>
    </>
  );
};

export default Form301;
