import { t } from "i18next";

export const REPORT_OPTION = [
  { label: "All Report", value: "all" },
  { label: "Hazard", value: "hazard" },
  { label: "Incident", value: "incident" },
  { label: "Maintenance", value: "maintenance" },
  { label: "General observation", value: "general_observation" },
  { label: "Near Miss", value: "near_miss" },
];

export const CLASSIFICATION_OPTION = {
  death: t("classificationOptions.death"),
  days_away_from_work: t("classificationOptions.days_away_from_work"),
  job_transfer_or_restriction: t(
    "classificationOptions.job_transfer_or_restriction"
  ),
  other_recordable_case: t("classificationOptions.other_recordable_case"),
};

export const INJURY_OPTION = {
  injury: t("injuryOptions.injury"),
  skin_disorder_skin_disorder: t("injuryOptions.skin_disorder_skin_disorder"),
  respiratory_condition: t("injuryOptions.respiratory_condition"),
  poisoning: t("injuryOptions.poisoning"),
  hearing_loss_hearing_loss: t("injuryOptions.hearing_loss_hearing_loss"),
  all_other_illness: t("injuryOptions.all_other_illness"),
};
