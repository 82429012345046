import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import Select from "react-select";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import CustomizedDialog from "../../Dialog";
import KBselection from "./selectKnowledgeBase";
import {
  ProjectLocationAutoCompleteSuggesstionThunk,
  createProjectThunk,
  getAllUsersThunk,
  updateProjectThunk,
} from "../../../../redux/slices/tenant.slice";
import { fetchAllPublicKBThunk } from "../../../../redux/slices/knowledgeBase.slice";
import AssistantSelectKB from "../../Assistants/AssistantSelectKB";

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    minHeight: "44px",
    border: "1px solid #0D333F54",
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    marginTop: 0,
    overflow: "auto",
    maxHeight: "280px",
  }),
  menuList: (base) => ({
    ...base,
    padding: "0",
    color: "#030F1C",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    overflow: "auto",
    maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: " #cccccc",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#273167",
    "&:hover": {
      color: "#273167",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "12px 16px",
    backgroundColor: state.isSelected ? "#0075FF" : "#FEFEFF",
    borderBottom: "1px solid #EAECF0",
    borderRadius: "8px 8px 0px 0px",
    "&:hover": {
      backgroundColor: state.isSelected ? "#0075FF" : "#F2F4F7",
      color: state.isSelected ? "#FFFFFF" : "#005ECC",
    },
  }),
};

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

let timeOut = null;
const CreateProject = (
  {
    drawerState,
    setDrawerState,
    setIsOpenDrawer,
    isOpenDrawer,
    fetchAllProject,
    viewOpen,
    selectedRow,
  },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    projectid: "",
    location: "",
    radius: "",
    notification_description: "",
    safety_description: "",
    project_safety_docs: {},
  });
  const [publicKb, setPublicKb] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup.string().required("Please, Enter project name"),
    description: yup.string().required("Please, Enter project description"),
    projectid: yup.string().required("Please, Enter project id"),
    location: yup.string().required("Please, Enter project location"),
    radius: yup.string().required("Please, Enter project radius"),
    notification_description: yup
      .string()
      .required("Please, Enter notification description"),
    safety_description: yup
      .string()
      .required("Please, Enter safety description"),
  });

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const fetchAllUsers = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 100000000,
        status: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));

      setUsers(temp);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(
    () => {
      fetchAllUsers();
    }, // eslint-disable-next-line
    []
  );

  const fetchAllKB = async () => {
    let response = await dispatch(fetchAllPublicKBThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.kbName,
        value: item.kbId,
      }));
      setPublicKb(temp);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllKB();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let matchPlaceId = null;
      locationOption?.map((element) => {
        if (element?.value == placeId) {
          matchPlaceId = element?.value;
        }
      });
      let kb = [];
      // values?.knowledgebase?.map((element) => {
      //   kb.push(element?.value);
      // });
      // values["knowledgebase"] = kb;

      let safety_management_team = [];
      values?.safety_management_team?.map((element) => {
        safety_management_team.push(element?.value);
      });
      values["safety_management_team"] = safety_management_team;

      setIsLoading(true);
      try {
        values["place_id"] = matchPlaceId;
        let response = await dispatch(
          drawerState === "edit"
            ? updateProjectThunk({
                ...values,
                id: selectedRow?.id,
              })
            : createProjectThunk(values)
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Project Successfully updated"
                  : "Project successfully created",
            })
          );
          setIsOpenDrawer(false);
          fetchAllProject();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (drawerState == "view" || drawerState == "edit") {
      let data = {
        name: selectedRow?.name,
        description: selectedRow?.description,
        projectid: selectedRow?.projectid,
        location: selectedRow?.location,
        radius: selectedRow?.radius,
        notification_description: selectedRow?.notification_description,
        safety_description: selectedRow?.safety_description,
      };

      // let tempKb = [];
      // selectedRow &&
      //   selectedRow?.knowledgebase?.map((element) => {
      //     publicKb?.map((kb) => {
      //       if (kb.value == element) {
      //         tempKb.push(kb);
      //       }
      //     });
      //   });
      // let tempKb = {
      //   459: [
      //     {
      //       fileName: "Path-to-the-next-normal-collection.pdf",
      //       fileurl:
      //         "https://pri42291sahndev.blob.core.windows.net/tenant-151/KB-459/f3496923-9d14-497a-a8fc-952a10bf0694.pdf",
      //     },
      //     {
      //       filename: "the-state-of-organizations-2023.pdf",
      //       fileurl:
      //         "https://pri42291sahndev.blob.core.windows.net/tenant-151/KB-459/1ebe808e-3cd9-4ae8-bb2e-abdc8934802a.pdf",
      //     },
      //   ],
      // };

      let tempSMT = [];
      selectedRow &&
        selectedRow?.safety_management_team?.map((element) => {
          users?.map((safety_management_team) => {
            if (safety_management_team.value == element.id) {
              tempSMT.push(safety_management_team);
            }
          });
        });
      data = {
        ...data,
        project_safety_docs: selectedRow?.project_safety_docs,
        safety_management_team: tempSMT,
      };

      formik.setValues(data);

      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: selectedRow?.location,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  }, [drawerState, selectedRow, publicKb]);

  const [locationOption, setLocationOption] = useState([]);
  const [placeId, setPlaceId] = useState(null);

  const handleAutocompleteChange = (event, values) => {
    formik?.setFieldValue("location", values);

    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };
  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={
          drawerState === "view"
            ? "Project Details"
            : drawerState === "create"
            ? "Create Project"
            : "Edit Project"
        }
        open={isOpenDrawer || viewOpen}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Project name
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Project name"
                    size="small"
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="form-error-message">
                      {formik.errors.name}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Project description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Project description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Project id
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Projectid"
                    size="small"
                    name="projectid"
                    {...formik.getFieldProps("projectid")}
                  />
                  {formik.touched.projectid && formik.errors.projectid && (
                    <div className="form-error-message">
                      {formik.errors.projectid}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Project location
                  </Typography>
                  <Autocomplete
                    className="project-location-autocomplete"
                    disabled={drawerState === "view"}
                    freeSolo
                    inputValue={formik.values.location}
                    onInputChange={(event, values) => {
                      handleAutocompleteChange(event, values);
                    }}
                    onChange={(options, value) => {
                      setPlaceId(value?.value);
                    }}
                    options={locationOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        id="outlined-basic"
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="location"
                        placeholder="Please, Enter project location  "
                      />
                    )}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className="form-error-message">
                      {formik.errors.location}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Radius ( ft )
                  </Typography>
                  <TextField
                    type="number"
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Project radius"
                    size="small"
                    name="radius"
                    {...formik.getFieldProps("radius")}
                  />
                  {formik.touched.radius && formik.errors.radius && (
                    <div className="form-error-message">
                      {formik.errors.radius}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Notification description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter notification description"
                    size="small"
                    name="notification_description"
                    {...formik.getFieldProps("notification_description")}
                  />
                  {formik.touched.notification_description &&
                    formik.errors.notification_description && (
                      <div className="form-error-message">
                        {formik.errors.notification_description}
                      </div>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Safety Management Team
                  </Typography>
                  <Select
                    styles={customStyles}
                    isMulti
                    name="kbs"
                    size="small"
                    fullWidth
                    className="basic-multi-select"
                    options={users}
                    value={formik?.values?.safety_management_team}
                    isDisabled={drawerState == "view" ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue("safety_management_team", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Safety Management Team..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Safety description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter safety description"
                    size="small"
                    name="safety_description"
                    {...formik.getFieldProps("safety_description")}
                  />
                  {formik.touched.safety_description &&
                    formik.errors.safety_description && (
                      <div className="form-error-message">
                        {formik.errors.safety_description}
                      </div>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Project Safety Docs
                  </Typography>
                  <KBselection
                    id={1}
                    formik={formik}
                    disabled={drawerState === "view"}
                    docs={selectedRow?.project_safety_docs}
                  />
                  {/* <Select
                    styles={customStyles}
                    isMulti
                    name="kbs"
                    size="small"
                    fullWidth
                    className="basic-multi-select"
                    options={publicKb}
                    value={formik?.values?.knowledgebase}
                    isDisabled={drawerState == "view" ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue("knowledgebase", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Knowledge Base(s)..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select> */}
                </Box>
              </Grid>

              {/* {drawerState == "edit" && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    mt={"15px"}
                  >
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography variant="body1" component="label">
                        Location latitude
                      </Typography>
                      <Autocomplete
                        className="project-location-autocomplete"
                        disabled={drawerState === "view"}
                        freeSolo
                        inputValue={formik.values.location}
                        onInputChange={(event, values) => {
                          handleAutocompleteChange(event, values);
                        }}
                        onChange={(options, value) => {
                          setPlaceId(value?.value);
                        }}
                        options={locationOption}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="location"
                          />
                        )}
                      />
                      {formik.touched.location && formik.errors.location && (
                        <div className="form-error-message">
                          {formik.errors.location}
                        </div>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    mt={"15px"}
                  >
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography variant="body1" component="label">
                        Location longitude
                      </Typography>
                      <Autocomplete
                        className="project-location-autocomplete"
                        disabled={drawerState === "view"}
                        freeSolo
                        inputValue={formik.values.location}
                        onInputChange={(event, values) => {
                          handleAutocompleteChange(event, values);
                        }}
                        onChange={(options, value) => {
                          setPlaceId(value?.value);
                        }}
                        options={locationOption}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="location"
                          />
                        )}
                      />
                      {formik.touched.location && formik.errors.location && (
                        <div className="form-error-message">
                          {formik.errors.location}
                        </div>
                      )}
                    </Box>
                  </Grid>
                </>
              )} */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {(drawerState !== "view" || drawerState === "edit") && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  {drawerState == "edit" ? "UPDATE" : "CREATE"}
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateProject;
