import React, { useEffect } from "react";
import "./App.css";
import RootRouter from "./routes/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import UserActivity from "../src/components/common/UserActivity";
import i18n from "./i18n/index";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins"].join(","),
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
    },
  });

  let location = useLocation();
  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/privacy-policy"
    ) {
      const scriptElement = document.createElement("script");
      scriptElement.id = "scriptElement";
      scriptElement.innerHTML = `localStorage.setItem("scriptElement", "c8094f3f-7c04-4176-99d9-13ca7baaab3f")`;

      document?.body && document?.body?.appendChild(scriptElement);

      const chatbotScriptElement = document.createElement("script");
      chatbotScriptElement.id = "chatbotScript";
      chatbotScriptElement.src = "/chatbotScript/prismChatbotJs-bundle.js";
      document?.body && document?.body?.appendChild(chatbotScriptElement);

      const styleElement = document.createElement("link");
      styleElement.id = "chatbotScriptLink";
      styleElement.href = "/chatbotScript/prismViewChatbot.css";
      styleElement.rel = "stylesheet";
      document?.body && document?.body?.appendChild(styleElement);
    } else {
      document.getElementById("scriptElement") &&
        document.getElementById("scriptElement")?.remove();

      document.getElementById("chatbotScript") &&
        document.getElementById("chatbotScript")?.remove();

      document.getElementById("chatbotScriptLink") &&
        document.getElementById("chatbotScriptLink")?.remove();

      document.getElementById("mainDiv") &&
        document.getElementById("mainDiv")?.remove();

      const chatbotLogoElements = document.getElementsByClassName(
        "chatbot-script-logo"
      );

      Array.from(chatbotLogoElements).forEach((element) => {
        element && element.parentNode.removeChild(element);
      });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <div className="App">
          <RootRouter />
          <UserActivity />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
