// Loader.js
import React from "react";
import { CircularProgress, Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        bgcolor: "rgba(0, 0, 0, 0.8)", // Dimmed background
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it is on top of other content
        pointerEvents: "none", // Allows clicks to pass through
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
