// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/english/translation.json";
import hi from "../locales/hindi/translation.json";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      translation: en,
    },
    hi: {
      translation: hi,
    },
  },
});

export default i18n;
